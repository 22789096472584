const APPROVED_FUNCTIONAL_KEYS = [
  'DASHBOARD_FROM_LS_KEY',
  'LokiQueryEditorExplainDefault',
  'LokiQueryEditorModeDefault',
  'PrometheusQueryEditorExplainDefault',
  'PrometheusQueryEditorModeDefault',
  'SearchBar_Hidden',
  'VisualizationSelectPane.ListMode',
  'VisualizationSuggestions.showTitle',
  'alerting-alertmanager',
  'dashboard.components.TransformationEditor.featureInfoBox.isDismissed',
  'datasources.settings.cloudInfoBox.isDismissed',
  'getting.started.create-users and teams',
  'getting.started.create-users-and-teams',
  'getting.started.grafana-fundamentals',
  'grafana.dashboard.editor.ui',
  'grafana.dashboard.previews',
  'grafana.dashboard.timepicker.history',
  'grafana.datasources.graphite.config.showMappingsHelp',
  'grafana.datasources.loki.browser.labels',
  'grafana.datasources.prometheus.browser.labels',
  'grafana.explore.logs.enableLogsVolume',
  'grafana.explore.logs.enableVolumeHistogram',
  'grafana.explore.logs.prettifyLogMessage',
  'grafana.explore.logs.showLabels',
  'grafana.explore.logs.showTime',
  'grafana.explore.logs.sortOrder',
  'grafana.explore.logs.wrapLogMessage',
  'grafana.explore.richHistory',
  'grafana.explore.richHistory.activeDatasourceOnly',
  'grafana.explore.richHistory.datasourceFilters',
  'grafana.explore.richHistory.migrated',
  'grafana.explore.richHistory.retentionPeriod',
  'grafana.explore.richHistory.starredTabAsFirstTab',
  'grafana.explore.style.graph',
  'grafana.legacyalerting.unifiedalertingpromo',
  'grafana.licence.warning.dismissUntil',
  'grafana.navigation.docked',
  'grafana.search.include.panels',
  'grafana.search.layout',
  'grafana.search.sort',
  'grafana.sectionNav.expanded',
  'grafana.serviceaccounts.showApiKeysMigrationInfo',
  'grafana.unifiedalerting.hideErrors',
  'notifications',
  'notifications/lastRead',
  'panel-copy',
  'panel-edit-section-Axes',
  'panel-edit-section-Bar links',
  'panel-edit-section-Column Styles',
  'panel-edit-section-Columns',
  'panel-edit-section-Display',
  'panel-edit-section-Grouping',
  'panel-edit-section-Layout',
  'panel-edit-section-Legend',
  'panel-edit-section-Lines-and-Limits',
  'panel-edit-section-Options',
  'panel-edit-section-Series overrides',
  'panel-edit-section-Thresholds',
  'panel-edit-section-Time regions',
];
const APPROVED_FUNCTIONAL_WILDCARD_KEYS = [
  { pattern: /^dashboard_impressions-\d+$/, key: 'dashboard_impressions-*' },
  { pattern: /^grafana\.dashboard\.editor\.ui\.optionGroup\[.*]$/, key: 'grafana.dashboard.editor.ui.optionGroup[*]' },
  { pattern: /^grafana\.explore\.datasource\./, key: 'grafana.explore.datasource.*' },
  { pattern: /^grafana\.explore\.history\./, key: 'grafana.explore.history.*' },
  { pattern: /^grafana\.navigation\.expanded\[.*]$/, key: 'grafana.navigation.expanded[*]' },
  { pattern: /^search\.sections\./, key: 'search.sections.*' },
];

export { APPROVED_FUNCTIONAL_KEYS, APPROVED_FUNCTIONAL_WILDCARD_KEYS };
